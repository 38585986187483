'use client'

import React, { useId, useState } from 'react'
import { Label, formClasses } from './Fields'

export default function PasswordInput({
    label,
    className,
    ...props
}: Omit<React.ComponentPropsWithoutRef<'input'>, 'id'> & { label: string }) {
    const [isVisible, setIsVisible] = useState(false)
    const id = useId()

    const toggleVisibility = () => setIsVisible(!isVisible)

    return (
        <div className={className}>
            {label && <Label id={id}>{label}</Label>}
            <div className="relative">
                <input id={id} type={isVisible ? 'text' : 'password'} {...props} className={formClasses} />
                <div className="absolute right-2 top-0 bottom-0 w-6 flex items-center justify-center">
                    <button
                        type="button"
                        onClick={toggleVisibility}
                        aria-label={isVisible ? 'Hide password' : 'Show password'}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="14"
                            viewBox="0 0 16 14"
                            fill="none"
                            className="text-neutral-500 scale-125"
                        >
                            <path
                                d="M10.5 7C10.5 8.38071 9.38071 9.5 8 9.5C6.61929 9.5 5.5 8.38071 5.5 7C5.5 5.61929 6.61929 4.5 8 4.5C9.38071 4.5 10.5 5.61929 10.5 7Z"
                                stroke="currentColor"
                            />
                            <path
                                d="M15.3315 6.44921L15.3316 6.44942C15.4401 6.60761 15.5 6.80022 15.5 7.00001C15.5 7.1998 15.4401 7.39241 15.3316 7.55059L15.3315 7.55081C14.493 8.7757 13.3899 9.77132 12.1152 10.4572C10.8406 11.143 9.43002 11.5 8 11.5C6.56998 11.5 5.15943 11.143 3.88482 10.4572C2.61007 9.77132 1.50702 8.7757 0.668499 7.55081L0.668353 7.55059C0.559941 7.39241 0.500002 7.1998 0.500002 7.00001C0.500002 6.80022 0.559942 6.60761 0.668353 6.44942L0.668499 6.44921C1.50702 5.22432 2.61007 4.2287 3.88482 3.54281C5.15943 2.857 6.56998 2.50001 8 2.50001C9.43002 2.50001 10.8406 2.857 12.1152 3.54281C13.3899 4.2287 14.493 5.22432 15.3315 6.44921Z"
                                stroke="currentColor"
                            />
                            <line
                                x1="1.70711"
                                y1="1"
                                x2="13.7279"
                                y2="13.0208"
                                stroke="currentColor"
                                strokeLinecap="round"
                                className={isVisible ? 'hidden' : ''}
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    )
}
