import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import clsx from 'clsx'
import React from 'react'
import { useId } from 'react'

export const ChevronDownIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
        <path d="M7 1.5L4 4.5L1 1.5" stroke="#89919D" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export const formClasses =
    'block w-full appearance-none rounded border border-neutral-200 px-3 py-2 text-neutral-900 placeholder-neutral-400 focus:border-primary-500 focus:bg-white focus:outline-none focus:ring-primary-500 text-xs'

export function Label({ id, children }: { id: string; children: React.ReactNode }) {
    return (
        <label htmlFor={id} className="mb-2 block font-normal text-xs text-neutral-900">
            {children}
        </label>
    )
}

export function TextField({
    label,
    type = 'text',
    className,
    ...props
}: Omit<React.ComponentPropsWithoutRef<'input'>, 'id'> & { label: string }) {
    const id = useId()
    return (
        <div className={className}>
            {label && <Label id={id}>{label}</Label>}
            <input id={id} type={type} {...props} className={formClasses} />
        </div>
    )
}

export function TextArea({
    label,
    className,
    hidden,
    ...props
}: Omit<React.ComponentPropsWithoutRef<'textarea'>, 'id'> & { label: string; hidden?: boolean }) {
    const id = useId()
    return (
        <div className={className} style={{ display: hidden === true ? 'none' : 'unset' }}>
            {label && (
                <label htmlFor={id} className="mb-3 block font-normal text-sm text-neutral-900">
                    {label}
                </label>
            )}
            <textarea id={id} {...props} className={formClasses} />
        </div>
    )
}

export interface ISelectOption {
    value: string
    text: string
    hidden?: boolean
}

export function SelectField({
    onChange,
    defaultValue,
    options,
    className,
    label,
}: {
    label: string | undefined
    className: string | undefined
    onChange: (value: string) => void
    defaultValue: string
    options: ISelectOption[]
}) {
    const id = useId()
    const [value, setValue] = React.useState(defaultValue)
    const selectedText = React.useMemo(() => {
        return options.find(option => option.value === value)!.text
    }, [value, options])
    return (
        <Listbox
            onChange={value => {
                setValue(value)
                onChange(value)
            }}
        >
            <div>
                {label && (
                    <label htmlFor={id} className="block mb-3 font-normal text-sm text-neutral-900">
                        {label}
                    </label>
                )}
                <ListboxButton
                    id={id}
                    className={clsx(
                        className ?? '',
                        'flex flex-grow items-center justify-between',
                        'w-full appearance-none rounded-md border border-neutral-200 px-3 py-2 text-[#3b4049] text-xs placeholder-[#3b4049] bg-white',
                    )}
                >
                    <div>{selectedText}</div>
                    <ChevronDownIcon />
                </ListboxButton>
            </div>
            <ListboxOptions
                anchor="bottom"
                transition
                className={clsx(
                    '[--anchor-gap:2px]',
                    'w-[var(--button-width)] shadow-sm border rounded-lg bg-white focus:outline-none',
                    'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0',
                    'py-2',
                )}
            >
                {options
                    .filter(option => option.hidden !== true)
                    .map(option => (
                        <ListboxOption
                            key={id + String(option.value)}
                            value={option.value}
                            className="group flex cursor-default items-center px-3 py-1 hover:bg-[#e7f2fc]"
                        >
                            <div className="text-xs text-[#3b4049]">{option.text}</div>
                        </ListboxOption>
                    ))}
            </ListboxOptions>
        </Listbox>
    )
}
