'use client'
import Link from 'next/link'
import { useSearchParams } from 'next/navigation'
import { useState } from 'react'
import { useUserInfoProvider } from 'src/hooks/providers/UserInfoProvider'
import { Button } from 'src/components/Button'
import ErrorMessage from 'src/components/ErrorMessage'
import { TextField } from 'src/components/Fields'
import LoadingSpinner from 'src/components/LoadingSpinner'
import OauthButtons from 'src/components/OauthButtons'
import PasswordInput from 'src/components/PasswordField'
import type { OAuthKinds } from 'src/utils/api'
import { OAuthScopes } from 'src/utils/api'
import { authorize, getSiteUrlPrefix, signin, track } from 'src/utils/api'

type FormData = {
    emailAddress: string
    password: string
}

const LoginForm = () => {
    const [isLoginPending, setIsLoginPending] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const [formData, setFormData] = useState<FormData>({
        emailAddress: '',
        password: '',
    })
    const searchParams = useSearchParams()
    const returnTo = searchParams.get('return_to') ?? ''
    const { forceReloadUser } = useUserInfoProvider()

    const handleApiError = (err: any) => {
        setError(err instanceof Error ? err.message : 'An unknown error occurred')
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }))
    }

    const createOauthSignin = (kind: OAuthKinds, scopes: string[]) => {
        return async () => {
            setError(null)
            setIsLoginPending(true)
            await track('signinStarted', { kind })
            try {
                const { redirectUrl } = await authorize(kind, scopes, {
                    returnTo,
                })
                // we should be using Next's router.replace() however it does not reliably
                //  include cookies and have not invested enough time in configuring
                //  Next to fit our needs. this could potentially hide bugs but for now is OK.
                window.location.href = redirectUrl ?? `${getSiteUrlPrefix()}/workspaces`
            } catch (error) {
                handleApiError(error)
            } finally {
                setIsLoginPending(false)
            }
        }
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setError(null)
        setIsLoginPending(true)
        await track('signinStarted', { kind: 'email', emailAddress: formData.emailAddress }).catch(err => {
            // eat this error
        })
        try {
            const { redirectUrl } = await signin(formData.emailAddress, formData.password, { returnTo })
            if (forceReloadUser !== undefined) {
                await forceReloadUser()
            }
            // we should be using Next's router.replace() however it does not reliably
            //  include cookies and have not invested enough time in configuring
            //  Next to fit our needs. this could potentially hide bugs but for now is OK.
            window.location.href = redirectUrl ?? `${getSiteUrlPrefix()}/workspaces`
        } catch (err) {
            handleApiError(err)
        } finally {
            setIsLoginPending(false)
        }
    }

    return (
        <div className="mt-8 mb-8 w-full">
            <p className="mb-3 text-xl text-neutral-900 font-bold">Sign in </p>
            <p className="mt-6 mb-3 text-xs text-neutral-900">Welcome back! Continue with:</p>
            <OauthButtons
                signinGitHub={createOauthSignin('github', OAuthScopes.github)}
                signinGitLab={createOauthSignin('gitlab', OAuthScopes.gitlab)}
                signinGoogle={createOauthSignin('google', OAuthScopes.google)}
            />
            <div className="relative flex py-4 items-center">
                <div className="flex-grow h-px bg-gray-300"></div>
                <div className="px-4 text-xs text-gray-500">Or</div>
                <div className="flex-grow h-px bg-gray-300"></div>
            </div>
            <form method="POST" onSubmit={handleSubmit} className="grid grid-cols-1 gap-y-8 mb-6">
                <div className="flex flex-col justify-center gap-y-4">
                    <TextField
                        label="Email"
                        name="emailAddress"
                        type="email"
                        placeholder="Enter your email"
                        autoComplete="email"
                        onChange={handleInputChange}
                        value={formData.emailAddress}
                        required
                    />
                    <div>
                        <PasswordInput
                            label="Password"
                            name="password"
                            autoComplete="current-password"
                            placeholder="Enter your password"
                            onChange={handleInputChange}
                            value={formData.password}
                            required
                        />
                        <div className="col-span-full flex flex-row justify-between mt-3 px-1 text-sm">
                            <span className="flex-1" />
                            <Link
                                href="/forgot-password"
                                className="font-medium text-primary-700 text-xs hover:underline"
                            >
                                Forgot password?
                            </Link>
                        </div>
                    </div>
                </div>
                <div>
                    <Button type="submit" variant="solid" color="blue" className="bg-primary-500 w-full !rounded">
                        <LoadingSpinner isLoading={isLoginPending}>
                            <span className="text-xs">Sign In</span>
                        </LoadingSpinner>
                    </Button>
                    <ErrorMessage>{error}</ErrorMessage>
                </div>
            </form>
            <p className="my-5 text-neutral-900 text-center text-xs">
                New to Circuitly?{' '}
                <Link href="/register" className="font-medium text-primary-700 text-xs hover:underline">
                    Create an account
                </Link>
            </p>
        </div>
    )
}

export default LoginForm
